<template>
	<div>
		<footer>
			<div class="row">
				<div class="columns column3">
					<div class="footer-logo" />
				</div>
				<div class="columns column3">
					<h4>{{ defaults[locale].website.hotelName }}</h4>
					<p>
						<span>{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}</span>
						<br />
						<span>{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}</span>
						<br />
						<span>{{ defaults[locale].website.country }}</span>
					</p>
					<div class="star-logo"></div>
				</div>
				<div class="columns column3">
					<h4>{{ defaults[locale].website.footerContact }}</h4>
					<p>
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`" class="footer-link">
							<font-awesome-icon :icon="`fal fa-phone`" size="1x" />
							<span> {{ defaults[locale].website.phone }}</span>
						</a>
						<br />
						<a :href="`mailto:${defaults[locale].website.email}`" class="footer-link">
							<font-awesome-icon :icon="`fal fa-envelope`" size="1x" />
							<span> {{ defaults[locale].website.email }}</span>
						</a>
					</p>
					<social-media :socials="socials" />
				</div>
				<div class="columns column3">
					<h4>{{ defaults[locale].website.footerNavigate }}</h4>
					<p>
						<nuxt-link v-for="item in footermenu[locale]" :key="item.filename" :to="item.filename">
							{{ item.header }} <br />
						</nuxt-link>
						<a href="#" @click="$emit('clickConsentOpen')">{{ $t('consentOpen') }}</a>
					</p>
				</div>
			</div>
			<div class="row author">
				<div class="columns column12 align-center">
					<div class="footer-border" />
					<p>
						<a
							href="https://www.porterforhotels.com/hotel-websites"
							target="_blank"
							rel="noopener noreferrer"
							title="Hotel website design by Porter"
						>
							Hotel Website Design
						</a>
						by:
						<a href="https://porterforhotels.com" target="_blank">
							<img class="siteByLogo" :src="$t('siteByLogo')" loading="lazy" alt="" />
							Porter</a
						>
					</p>
				</div>
			</div>
		</footer>
	</div>
</template>

<script setup>
const { data: footermenu } = await useWebsiteFetch('footermenu');
const { locale } = useI18n();

const { defaults, socials, fetchDefaults } = useDefaults();

await fetchDefaults();

defineEmits(['clickConsentOpen']);
</script>

<style lang="scss" scoped>
footer {
	background: $footer-background-color;
	color: $footer-link-color;
	padding: 50px 0 10px;
	font-size: 0.95rem;
}

h3,
h4 {
	color: #333;
}

.footer-link svg {
	color: $primary-color;
	margin: 0 5px 0 0;
	padding-right: 5px;
}

.footer-border {
	width: 100%;
	height: 2px;
	background: #696969;
	margin: 0 0 20px;
}

a {
	color: $footer-link-color;
	text-decoration: none;

	&:hover {
		color: $primary-color;
	}
}

.author {
	margin: 25px auto 0;
	font-size: 12px;
	padding: 20px 0;

	p {
		margin: 0;
		font-size: 12px;
	}

	img {
		max-width: 20px;
		margin: 0 1px 0 2px;
	}
}

@media (max-width: 900px) {
	footer .columns {
		margin: 20px auto;
		text-align: left;
	}

	footer.author .columns {
		text-align: center;
	}
}

@media (max-width: 600px) {
	footer {
		.socials {
			justify-content: center;
			align-items: center;
		}

		.columns {
			text-align: center;
		}
	}

	.footer-logo {
		margin: 0 auto 10px;
	}
}
</style>
